require('../js/custom')
require('../js/fontawesome')
// require('../js/scrolling-nav')

document.addEventListener('turbolinks:load', () => {
  // Link on full Excerpt
  if( $("[data-link]").length ) {
    $("[data-link]").click(function() {
      window.location.href = $(this).attr("data-link");
      return false;
    });
  };

  // EXIT-POPUP
  function onMouseOutBlog(event) {
    // If the mouse is near the top of the window, show the popup
    // Also, do NOT trigger when hovering or clicking on selects (bug firefox)
    if (
      event.clientY < 50 &&
      event.relatedTarget == null &&
      event.target.nodeName.toLowerCase() !== 'select') {
      // Remove this event listener
      document.removeEventListener("mouseout", onMouseOutBlog);

      // Show the popup
      $('#mailerLitePopup').modal('show');
    }
  }

  if (($('.posts.show')) && ($('#mailerLitePopup'))) {
    document.addEventListener("mouseout", onMouseOutBlog);
  }
});

// validation
(function() {
  'use strict';
  window.addEventListener('turbolinks:load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');

    // Add the novalidate attribute when the JS loads so that if our script has an error or fails to load, the native browser form validation will still work
    for (var i = 0; i < forms.length; i++) {
      forms[i].setAttribute('novalidate', true);
    }

    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
})();

document.addEventListener('turbolinks:load', () => {
  // smooth scrolling nav
  (function($) { "use strict"; // Start of use strict
    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top)
          }, 1000, "easeInOutExpo");
          return false;
        }
      }
    });
    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function() {
      $('.navbar-collapse').collapse('hide');
    });
    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
      target: '#toc'
    });
  })(jQuery); // End of use strict
})
